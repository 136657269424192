import React, { useState, useEffect } from "react";
import NavBar2 from "../NavBar2";
import { apis } from "../../apis";
import axios from "axios";
import Cookies from "js-cookie";
import Adminaside from "../../components/asides/Adminaside";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { get_company } from "../../redux/slice/createcompany";
import AdminHeader from "../../components/comman/admin_header";
import { Col, Row } from "react-bootstrap";

const CreateCompany = () => {
  let token = Cookies.get("token");
  const navigate = useNavigate()
  // state for form data store
  const formInit = {
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  }
  const [formData, setFormData] = useState(formInit);
  const [loading, setLoading] = useState(false);
  const [resError, setResError] = useState('')
  const [error, setError] = useState(formData)
  const [successToast, setSuccessToast] = useState(false);
  const [errToast, setErrToast] = useState(false);
  const dispatch = useDispatch()
  // Handle form input chage
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // handel onSumit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let isError = false;
      let errorList = {}
      Object.keys(formData).map((it) => {
        if (formData[it] == '') {
          errorList[it] = `${it} is required!`
          setErrToast(true)
          isError = true
        }
      })
      setError(errorList)
      if (isError) {
        return
      }
      let headers = {
        token: token,
      };
      const res = await axios.post(apis.CREATE_COMPANY, formData, { headers });
      setSuccessToast(true);
      setFormData(formInit)
      dispatch(get_company({ token }))
      setTimeout(() => {
        navigate('/admin/company/dashboard')
      }, 4000)
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.msg) {
        setResError(error?.response?.data?.msg)
      } else {
        setResError(error?.message)
      }
    } finally {
      setLoading(false);
    }

  };


  useEffect(() => {
    if (successToast) {
      toast.success("Company Created Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }, [successToast]);

  useEffect(() => {
    if (errToast) {
      Object.values(error).map(it => {
        toast.error(it, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      })

      setTimeout(() => {
        setErrToast(false)
      }, 1000)
    }
  }, [errToast]);

  useEffect(() => {
    if (resError !== '') {
      toast.error(resError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }, [resError]);

  return (
    <>
      <div className="admin-dashboard">
        <div className="admin-nav">
          <NavBar2 />
        </div>
        {/* dashboard  */}
        <div className="admin-container ">
          {/* aside  */}
          <div className="aside text-center align-item-center">
            <Adminaside />
          </div>
          <AdminHeader backTitle="Dashboad" backPath="/admin" action_path="/admin/company/dashboard" action="All Companies" />
          <div className="create-user">
            <form action="" onSubmit={handleSubmit}>
              <h1 className="text-center mb-3">Create Company</h1>
              <div className="container">
                <Row>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="py-2">
                    <TextField
                      id="outlined-required"
                      label="Name"
                      value={formData?.name}
                      onChange={handleInputChange}
                      name="name"
                      fullWidth
                      // variant="standard"
                      color={formData.name ? "success" : ''}
                    />
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="py-2">
                    <TextField
                      id="outlined-required"
                      label="City"
                      value={formData?.city}
                      onChange={handleInputChange}
                      name="city"
                      color={formData.city ? "success" : ''}
                      fullWidth
                    // variant="standard"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="py-2">
                    <TextField
                      id="outlined-required"
                      label="Address"
                      value={formData?.address}
                      onChange={handleInputChange}
                      name="address"
                      color={formData.address ? "success" : ''}
                      fullWidth
                    // variant="standard"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="py-2">
                    <TextField
                      id="outlined-required"
                      label="State"
                      value={formData?.state}
                      onChange={handleInputChange}
                      name="state"
                      color={formData.state ? "success" : ''}
                      fullWidth
                    // variant="standard"
                    />
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="py-2">
                    <TextField
                      id="outlined-required"
                      label="Zip"
                      value={formData?.zip}
                      onChange={handleInputChange}
                      name="zip"
                      color={formData.zip ? "success" : ''}
                      fullWidth
                    // variant="standard"
                    />
                  </Col>
                </Row>

                <div className="mt-2 d-flex justify-content-center">
                  <button type={loading ? "button" : 'submit'} className="btn btn-success">
                    {loading ? "Loading..." : "Create Company"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompany;
